<template>
  <div class="addAdministrator">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{this.$route.query.imgUrl?'修改':'添加'}}打卡地</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      class="demo-form-inline"
      :model="formList"
      :rules="rules"
      ref="ruleForm"
    >
      <!-- <el-form-item label="打卡地id" prop="userName">
        <el-input
          v-model.trim="gameInfo.gameId"
          placeholder="打卡地id"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="" prop="userName">
        <span>名称</span>
        <el-input
        value = "gameInfo.title"
        v-model.trim="clock.placeName"
        placeholder="打卡地名称"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="" prop="userTrueName" style="margin-right:12px;">
        <span>地点ID</span>
        <el-input
          v-model.trim="clock.placeId"
          placeholder="地点ID"
        ></el-input>
      </el-form-item> -->
      
      <el-form-item label="" prop="userTrueName" >
          <span>{{this.$route.query.imgUrl?'修改图片':'添加图片'}}</span>
          <div style="display:flex;justify-content: baseline;align-item:center;">
            <send-image
          style="width:100px;margin-right:20px"
          :images="formList.imgUrl"
          @addImageStr="addImageStr"
        ></send-image>
        <span style="color:red">推荐尺寸:宽: 689px;高: 929px;</span>

          </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="confirmAddClock">保存</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { clockList,addClock,removeClock} from "../../../api/smart";
import SendImage from "../../../components/sendImage.vue";
import sha1 from "sha1";
export default {
  name: "AddAdministrator",
  components:{
  SendImage
  },
  data() {

    return {
        //时间选择器
         pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }],
        },
        value1: '',
        value2: '',
        value3: '',
      checkPass: "",
      sexValue: "",
      formList: {
        userName: "",
        userTrueName: "",
        userPassword: "",
        userHeadImg: "",
        userPhone: "",
        roleId: null,
        userSex: null,
      },
      roleList: [], //角色列表
      value: "", //角色主键
      src:
        "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      rules: {
        // userPassword: [{ validator: validatePass, trigger: "blur" }],
        // checkPass: [{ validator: validatePass2, trigger: "blur" }],
        // userPhone: [{ validator: validateTelNumber, trigger: "blur" }],
        // userName: [{ validator: validateUName, trigger: "blur" }],
        // userTrueName: [{ validator: validateTrueUName, trigger: "blur" }],
        // fkName: [{ validator: validateTrueUName2, trigger: "blur" }],
      },
      clock:{
          placeName:'',
          placeId:'0',
          imgUrl:'',
      }
    };
  },
  watch: {
    sexValue: function () {
      this.formList.userSex = Number(this.sexValue);
    },
  },
  created() {
    this.clock.placeName = this.$route.query.placeName
    this.clock.placeId = this.$route.query.placeId
    this.clock.imgUrl = this.$route.query.imgUrl
    this.formList.imgUrl = this.$route.query.imgUrl
  },
  methods: {
      addImageStr(image) {
        // image = this.$route.query.imgUrl
      this.clock.imgUrl = image;
    },
      //添加打卡地
      async confirmAddClock(){
        console.log('asllalal')
          await addClock(this.clock)
          if(this.clock.placeName && this.clock.imgUrl){
              this.$message({
          message: '保存成功',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000);
          }else{
                 this.$message({
          message: '请输入完整的数据',
          type: 'warning'
        });
          }
      },
  },
};
</script>
<style lang="less" scoped>
.addAdministrator {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form {
    margin-top: 50px;
    .el-form-item {

      .el-form-item__content {
        display: flex;
        span{
          width:180px;
        }
        .el-form-item__error {
          margin-left: 75px;
        }
        
      }
    }
    .avatar {
      display: flex;
      align-items: center;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
        .el-button {
          margin-right: 20px;
        }
      }
    }
    .el-form-item__label {
      width: 80px;
    }
    .el-input {
      width: 500px;
      
    }
  }
}
</style>